import React, { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Layout

const ProtectedLayout = lazy(() => import('./ProtectedLayout.js'));

// Errors

const ForbiddenPage = lazy(() => import('./Pages/ForbiddenPage/ForbiddenPage.js'));

const Error404Page = lazy(() => import('./Pages/Error404Page/Error404Page.js'));

// Router

const router = createBrowserRouter([
	{
		path: "/",
		element: <ProtectedLayout />,
		errorElement: <Error404Page />,
		children: [
			{
				index: true,
				Component: lazy(() => import('./Pages/HomePage/HomePage.js'))
			},
			{
				path: 'login',
				Component: lazy(() => import('./Pages/LoginPage/LoginPage.js'))
			},
			{
				path: 'account',
				Component: lazy(() => import('./Pages/AccountPage/AccountPage.js'))
			},
			{
				path: 'calendar',
				Component: lazy(() => import('./Pages/CalendarPage/CalendarPage.js'))
			},
			{
				path: 'todo',
				Component: lazy(() => import('./Pages/TodosPage/TodosPage.js'))
			},
			{
				path: 'all',
				Component: lazy(() => import('./Pages/AllTodosPage/AllTodosPage.js'))				
			}
		]
	}
]);

//

function AppRouter() {

  return <RouterProvider router={router} />;

}

export default AppRouter;